import React from 'react';
import styles from './errors.module.scss';
import classNames from 'classnames';
import { Button, ButtonType, ButtonColor } from '../button/Button';
import { translate } from '@/translations/static/staticTranslations';

interface IErrorPage {
    language: string;
    bgClass: string;
    firstHeader: string;
    secondHeader: string;
    errorCode: string;
    buttonLabel: string;
}

export const ErrorPage: React.FC<IErrorPage> = (props) => {
    return (
        <div className={classNames(styles.container, props.bgClass)}>
            <div className={styles.containerTextContent}>
                <p className={styles.containerTextContentFirstHeader}>{props.firstHeader}</p>
                <p className={styles.containerTextContentSecondHeader}>{props.secondHeader}</p>
                <p className={styles.containerTextContentErrorCode}>{props.errorCode}</p>
                <Button
                    classes={styles.containerTextContentButton}
                    buttonType={ButtonType.LINK}
                    buttonColor={ButtonColor.BLACK}
                    url={translate(props.language, 'routes.home')}>
                    {props.buttonLabel}
                </Button>
            </div>
        </div>
    );
};
