'use client';

import React from 'react';
import styles from '@/components/errors/errors.module.scss';
import { useTranslation } from '../i18n/useTranslationClient';
import { ErrorPage } from '@/components/errors/ErrorPage';

export const InternalErrorPage = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    // TODO BANK-225 - remove layout from error pages
    return (
        <ErrorPage
            language={language}
            bgClass={styles.internalErrorBg}
            firstHeader={t('translations.errorPage.internalError.header')}
            secondHeader={t('translations.errorPage.internalError.secondHeader')}
            errorCode={t('translations.errorPage.internalError.errorCode')}
            buttonLabel={t('translations.errorPage.internalError.buttonLabel')}
        />
    );
};

export default InternalErrorPage;
